import * as React from "react"
import {Carousel} from "react-bootstrap";

export default function Images(props) {

    return (
        <Carousel>
            {props.images.map(function(image,index){
                return <Carousel.Item key={index}>
                    <img
                        className="d-block w-100"
                        src={image.url}
                    />
                </Carousel.Item>
            })}
        </Carousel>
    )
}

