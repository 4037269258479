import React, {useEffect, useState} from 'react';
import Layout from "../components/layout";
import {graphql, Link} from "gatsby";
import {renderRichText} from "gatsby-source-contentful/rich-text";
import {Helmet} from "react-helmet";
import FollowUs from "../components/follow-us";
import axios from "axios";
import Moment from 'react-moment';
import Players from "../components/elements/players";
import Goalies from "../components/elements/goalies";
import Images from "../components/elements/images";
import Footer from "../components/footer";
import Header from "../components/header";
import GameResult from "../components/elements/gameResult";
import IceRinkLive from "../components/elements/iceRinkLive";
import GameEvents from "../components/elements/gameEvents";
import GamePlayers from "../components/elements/gamePlayers";

function BlogPostSingle({data, location}) {

    const partners = data.allContentfulPartners.nodes;
    const post = data.contentfulBlogPost;
    const blogPosts = data.allContentfulBlogPost.nodes;
    const teams = data.allContentfulTeam.nodes
    const pages = data.allContentfulPages.nodes

    const [gameData,setGameData] = useState(null);
    const [gameStat,setGameStat] = useState(null);
    const [gameEvents,setGameEvents] = useState(null);

    async function getGame(gameId){
        const APIKEY = '8a1e0c57b1ea70f8063d2874d1c3f670bed22d68';
        const API = 'https://api.icehockey.hu/vbr/v1';

        try {
            axios.get(API+'/gameData', {
                params: {
                    gameId:gameId
                },
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-KEY': APIKEY,
                },
            }).then((response) =>{
                setGameData(response.data.data);
            });
        } catch (error) {
            console.error(error);
        }
    }

    async function getGameStat(gameId){
        const APIKEY = '8a1e0c57b1ea70f8063d2874d1c3f670bed22d68';
        const API = 'https://api.icehockey.hu/vbr/v1';

        try {
            axios.get(API+'/gameStats', {
                params: {
                    gameId:gameId
                },
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-KEY': APIKEY,
                },
            }).then((response) =>{
                setGameStat(response.data.data);
            });
        } catch (error) {
            console.error(error);
        }
    }



    async function getGameEvents(gameId){
        const APIKEY = '8a1e0c57b1ea70f8063d2874d1c3f670bed22d68';
        const API = 'https://api.icehockey.hu/vbr/v1';

        try {
            axios.get(API+'/gameEvents', {
                params: {
                    gameId:gameId
                },
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-KEY': APIKEY,
                },
            }).then((response) =>{
                setGameEvents(response.data.data);
            });
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(()=>{
        post.matchId && getGame(post.matchId)
        post.matchId && getGameStat(post.matchId)
        post.matchId && getGameEvents(post.matchId)
    },[])

    return (
        <Layout>
            <Helmet>
                <title>{post.title} - Zempléni Hiúzok</title>

                <meta property={'og:title'} content={post.title}/>
                <meta property={'og:url'} content={location.href}/>
                <meta property={'og:type'} content={'website'}/>
                <meta property={'og:description'} content={post.previewText}/>
                <meta property={'og:image'} content={post.thumbnail.url}/>

                <meta name={'twitter:card'} content={'summary'}/>
                <meta name={'twitter:title'} content={post.title}/>
                <meta name={'twitter:description'} content={post.previewText}/>
                <meta name={'twitter:url'} content={location.href}/>
                <meta name={'twitter:image'} content={post.thumbnail.url}/>
            </Helmet>
            <Header pages={pages} teams={teams}/>
            <main>
                <section className="section section-sm bg-gray-100">
                    <div className="container">
                        <div className="row row-30">
                            <div className="col-lg-8">
                                <div className="blog-post">
                                    {post.label && post.label.map(function(label,index){
                                        return <div key={index} className="badge badge-primary">{label}</div>
                                    })}
                                    <h3 className="blog-post-title">{post.title}</h3>

                                    <div className="blog-post-header">
                                        <div className="blog-post-meta">
                                            <time className="blog-post-time" dateTime={post.publishedAt}>
                                                <span className="icon mdi mdi-clock"/> {post.publishedAt}
                                            </time>
                                        </div>
                                    </div>

                                    <div className="blog-post-author-quote">
                                        <p>{post.previewText}</p>
                                    </div>

                                    <div className="blog-post-content">
                                        {renderRichText(post.text)}
                                    </div>
                                </div>

                            </div>

                            <div className="col-lg-4">
                                <div className="block-aside">
                                    <div className="block-aside-item">
                                        <article className="heading-component">
                                            <div className="heading-component-inner">
                                                <h5 className="heading-component-title">További hírek</h5>
                                                <Link to={'/hirek'} className="button button-xs button-gray-outline">Összes</Link>
                                            </div>
                                        </article>

                                        <div className="list-post-classic">
                                            {blogPosts.map(function(recentPost,index){
                                                return <article key={index} className="post-classic">
                                                    <div className="post-classic-aside">
                                                        <Link className="post-classic-figure" to={`/hirek/${recentPost.slug}`}>
                                                            <img src={recentPost.thumbnail.url} alt={recentPost.title} width="94"/>
                                                        </Link>
                                                    </div>
                                                    <div className="post-classic-main">
                                                        {recentPost.label && recentPost.label.map(function(label,index){
                                                            return <div key={index} className="badge badge-primary">{label}</div>
                                                        })}
                                                        <p className="post-classic-title">
                                                            <Link to={`/hirek/${recentPost.slug}`}>{recentPost.title}</Link>
                                                        </p>
                                                        <div className="post-classic-time">
                                                            <span className="icon mdi mdi-clock"/>
                                                            <time dateTime={recentPost.publishedAt}>{recentPost.publishedAt}</time>
                                                        </div>
                                                    </div>
                                                </article>
                                            })}

                                        </div>
                                    </div>
                                    <div className="block-aside-item">
                                        <FollowUs/>
                                    </div>

                                    {post.images && <div className="block-aside-item">
                                        <article className="heading-component">
                                            <div className="heading-component-inner">
                                                <h5 className="heading-component-title">Képek</h5>
                                            </div>
                                        </article>
                                        <Images images={post.images}/>
                                    </div>}

                                </div>
                            </div>

                            <div className={'col-12 gameResult'}>
                                {gameData && <div className={'gameData'}>
                                    <GameResult gameData={gameData}/>

                                    {gameEvents && gameEvents.length !== 0 && <GameEvents gameEvents={gameEvents}/>}
                                    <GamePlayers gameData={gameData} gameStat={gameStat}/>
                                </div>
                                }
                            </div>

                        </div>
                    </div>
                </section>

            </main>
            <Footer partners={partners}/>
        </Layout>
    );
}

export default BlogPostSingle;

export const query = graphql`
  query BlogPostBySlug(
    $slug: String!
  ) {
    contentfulBlogPost(slug: {eq: $slug}) {
    title
    slug
    publishedAt
    label
    previewText
    matchId
    thumbnail {
        url
    }
    images {
      url
    }
    text {
      raw
    }
  }
  allContentfulBlogPost(
    filter: {slug: {ne: $slug}}
    sort: {fields: publishedAt, order: DESC}
    limit: 5
    ) {
    nodes {
      slug
      title
      thumbnail {
        url
      }
      publishedAt
      label
      previewText
      matchId
      images {
        url
      }
    }
  }
  allContentfulPartners {
    nodes {
      title
      logo {
        url
      }
    }
  }
  allContentfulPages {
    nodes {
      text {
        raw
      }
      title
      slug
    }
  }
  allContentfulTeam(sort: {fields: title, order: ASC}) {
    nodes {
      title
      label
    }
  }
  }

`
